import React from 'react';
import {KeyServicesSection,KeyServicesLeft,KeyServicesRight,KeyServicesCard,
KeyServicesCardImg,KeyServicesCardIcon,KeyServicesCardHeading,KeyServicesCardPara,
KeyServicesLeftH3,KeyServicesLeftPara,KeyServicesLeftBtn,KeyServicesRow,KeyServicesCardtopMargin
} from './keyservices.style';
import {Container, Row, Col} from '@bootstrap-styled/v4';

import Fade from "react-reveal/Fade";
import { useStaticQuery, graphql } from 'gatsby';

const KeyServices = () => {
    const JSONData  = useStaticQuery(graphql`
        query{
            corporate2Json {
                KeyServices {
                    DelayConstant
                    KeyServicesLeft{
                        KeyServicesLeftH3
                        KeyServicesLeftPara
                        KeyServicesLeftBtn
                    }
                    KeyServicesRight1{
                        KeyServicesCardIcon
                        KeyServicesCardHeading
                        KeyServicesCardPara
                    }
                    KeyServicesRight2{
                        KeyServicesCardIcon
                        KeyServicesCardHeading
                        KeyServicesCardPara
                    }
                    
                }
            }
        }
    `);

    return (
        <KeyServicesSection id="KeyServicesContainer">
            <Container>
                <KeyServicesRow>
                    <Col md="12" lg="4">
                        <Fade delay={1*JSONData.corporate2Json.KeyServices.DelayConstant}>
                            <KeyServicesLeft>
                                <KeyServicesLeftH3>{JSONData.corporate2Json.KeyServices.KeyServicesLeft.KeyServicesLeftH3}</KeyServicesLeftH3>
                                <KeyServicesLeftPara>{JSONData.corporate2Json.KeyServices.KeyServicesLeft.KeyServicesLeftPara}</KeyServicesLeftPara>
                                <KeyServicesLeftBtn href="#">{JSONData.corporate2Json.KeyServices.KeyServicesLeft.KeyServicesLeftBtn}</KeyServicesLeftBtn>
                            </KeyServicesLeft>
                        </Fade>
                    </Col>
                    <Col md="12" lg="8">
                        <KeyServicesRight>
                            <Row>
                                <Col lg="6" md="6" sm="12">
                                    <KeyServicesCardtopMargin>
                                        {
                                            JSONData.corporate2Json.KeyServices.KeyServicesRight1.map((item,idx) => {
                                            return  <Fade delay={(idx+1)*JSONData.corporate2Json.KeyServices.DelayConstant}>
                                                <KeyServicesCard>
                                                    <KeyServicesCardImg>
                                                        <KeyServicesCardIcon src={item.KeyServicesCardIcon} alt=""/>
                                                    </KeyServicesCardImg>
                                                    <KeyServicesCardHeading>{item.KeyServicesCardHeading}</KeyServicesCardHeading>
                                                    <KeyServicesCardPara>{item.KeyServicesCardPara}</KeyServicesCardPara>
                                                </KeyServicesCard>
                                            </Fade>
                                            })
                                        }
                                    </KeyServicesCardtopMargin>
                                </Col>
                                <Col lg="6" md="6" sm="12">
                                    {
                                        JSONData.corporate2Json.KeyServices.KeyServicesRight2.map((item,idx) => {
                                        return <Fade delay={(idx+1)*JSONData.corporate2Json.KeyServices.DelayConstant}>
                                                <KeyServicesCard>
                                                    <KeyServicesCardImg>
                                                        <KeyServicesCardIcon src={item.KeyServicesCardIcon} alt=""/>
                                                    </KeyServicesCardImg>
                                                    <KeyServicesCardHeading>{item.KeyServicesCardHeading}</KeyServicesCardHeading>
                                                    <KeyServicesCardPara>{item.KeyServicesCardPara}</KeyServicesCardPara>
                                                </KeyServicesCard>
                                            </Fade>
                                        })
                                    }
                                </Col>
                            </Row>
                        </KeyServicesRight>
                    </Col>
                </KeyServicesRow>
            </Container>
        </KeyServicesSection>

    );
}

export default KeyServices;