import React,{Component} from 'react';
import {Container,Row,Col} from '@bootstrap-styled/v4';
import {TeamSlider,TeamSection,TeamSliderCol,TeamImgFigure,
    FbIcon,TwitterIcon,LinkedinIcon,InstaIcon,TeamDesc,TeamName,TeamDesignation,TeamSocial,
    TeamSliderDiv,NextPrev,ImgButton,PrevImg,NextImg} from './team.style';
import {SectionHeading} from '../Common/common.style';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GatsImg from "gatsby-image";

import { StaticQuery, graphql } from 'gatsby';
import Fade from "react-reveal/Fade";

class TeamComponent extends Component{

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render(){

    var settings = {
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 1
                }
            },
            {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
            }
            }
        ]
    };

    return (
            <TeamSection id="teamContainer">
                <Container>
                    <Row>
                        <Col md="12">
                            <Fade delay={1*this.props.corporate2Json.Team.DelayConstant}>
                                <SectionHeading>{this.props.corporate2Json.Team.SectionHeading}</SectionHeading>
                            </Fade>
                        </Col>
                    </Row>

                </Container>
                <Container>
                    <Fade delay={this.props.corporate2Json.Team.DelayConstant}>
                        <TeamSlider>
                            <Slider ref={c => (this.slider = c)} {...settings}>
                            {
                                this.props.corporate2Json.Team.TeamSlider.map((item,idx) => {
                                return  <TeamSliderCol><TeamSliderDiv>
                                            <TeamDesc>
                                                <TeamImgFigure>
                                                    <GatsImg 
                                                        fluid={item.TeamSliderImg.childImageSharp.fluid} 
                                                        className="TeamSliderImg"
                                                        alt=""
                                                    />
                                                </TeamImgFigure>
                                                <TeamName>{item.TeamName}</TeamName>
                                                <TeamDesignation>{item.TeamDesignation}</TeamDesignation>
                                                <TeamSocial href="#" aria-label="Team Link">
                                                    <FbIcon/>
                                                </TeamSocial>
                                                <TeamSocial href="#" aria-label="Team Link">
                                                    <TwitterIcon/>
                                                </TeamSocial>
                                                <TeamSocial href="#" aria-label="Team Link">
                                                    <LinkedinIcon/>
                                                </TeamSocial>
                                                <TeamSocial href="#" aria-label="Team Link">
                                                    <InstaIcon/>
                                                </TeamSocial>
                                            </TeamDesc>
                                        </TeamSliderDiv>
                                    </TeamSliderCol>
                                })
                            }
                            </Slider>
                        </TeamSlider>
                    </Fade>
                    <Fade delay={this.props.corporate2Json.Team.DelayConstant}>
                        <NextPrev>
                                <ImgButton onClick={this.previous} aria-label="Prev Button">
                                    <PrevImg src={this.props.corporate2Json.Team.PrevImg} alt=""/>
                                </ImgButton>
                                <ImgButton onClick={this.next} aria-label="Next Button">
                                    <NextImg src={this.props.corporate2Json.Team.NextImg} alt=""/>
                                </ImgButton>
                        </NextPrev>
                    </Fade>
                </Container>
            </TeamSection>
        );
    }
}

const Team = () => (
    <StaticQuery
    query={graphql`
        query{
            corporate2Json {
                Team {
                    DelayConstant
                    SectionHeading
                    TeamSlider{
                        TeamSliderImg{
                            childImageSharp{
                                fluid(quality: 100){
                                ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        TeamName
                        TeamDesignation
                    }
                    PrevImg
                    NextImg
                }
            }
        }
    `}
        render={(data) => (
            <TeamComponent corporate2Json={data.corporate2Json}/>
        )}
    />
)
export default Team;