import React,{Component} from 'react';
import {Container,Row,Col} from '@bootstrap-styled/v4';
import  {PorfolioSlider,PortfolioSection,PorfolioSliderCol,PortfolioHover,PortfolioHoverHeading,PortfolioHoverPara,
    PortfolioInnerContainer,PrevImg,NextImg,NextPrev,ImgButton} from './portfolio.style';
import {SectionHeading} from '../Common/common.style';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { StaticQuery, graphql } from 'gatsby';
import Fade from "react-reveal/Fade";
import GatsImg from "gatsby-image";

class Portfolio extends Component{

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render() {
        const settings = {
            className: "center-portfolio",
            centerMode: false,
            infinite: true,
            arrows:false,
            centerPadding:"0px",
            slidesToShow: 2,
            speed: 1000,
            responsive: [
               
                {
                  breakpoint: 576,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                  }
                }
            ]
        };

        return(
                <PortfolioSection id="portfolioContainer">
                    <Container>
                        <Row>
                            <Col md="12">
                                <Fade delay={1*this.props.corporate2Json.Portfolio.DelayConstant}>
                                    <SectionHeading>{this.props.corporate2Json.Portfolio.SectionHeading}</SectionHeading>
                                </Fade>
                            </Col>
                        </Row>

                    </Container>
                    <Container>
                        <PorfolioSlider>
                            <Slider ref={c => (this.slider = c)} {...settings}>
                            {
                                this.props.corporate2Json.Portfolio.PorfolioSlider.map((item,idx) => {
                                return  <Fade delay={(idx+1)*this.props.corporate2Json.Portfolio.DelayConstant}>
                                            <PorfolioSliderCol>
                                                <PortfolioInnerContainer>
                                                    <GatsImg 
                                                        fluid={item.PorfolioSliderImg.childImageSharp.fluid} 
                                                        className="PorfolioSliderImg"
                                                        alt=""
                                                    />
                                                    <PortfolioHover>
                                                        <PortfolioHoverHeading>
                                                        {item.PorfolioSliderTitle}
                                                        </PortfolioHoverHeading>
                                                        <PortfolioHoverPara>
                                                        {item.PorfolioSliderHoverText}
                                                        </PortfolioHoverPara>
                                                    </PortfolioHover>
                                                </PortfolioInnerContainer>
                                            </PorfolioSliderCol>
                                    </Fade>
                                })
                            }
                        </Slider>
                        </PorfolioSlider>
                        <NextPrev>
                            <ImgButton onClick={this.previous} aria-label="Prev Button">
                                <PrevImg src={this.props.corporate2Json.Portfolio.PrevImg} alt=""/>
                            </ImgButton>
                            <ImgButton onClick={this.next} aria-label="Next Button">
                                <NextImg src={this.props.corporate2Json.Portfolio.NextImg} alt=""/>
                            </ImgButton>
                        </NextPrev>
                    </Container>
                </PortfolioSection>
        );
    }
}

export default () => (
    <StaticQuery
        query={graphql`
            query {
                    corporate2Json{
                        Portfolio{
                            SectionHeading
                            PorfolioSlider{
                                PorfolioSliderTitle
                                PorfolioSliderHoverText
                                PorfolioSliderImg{
                                    childImageSharp{
                                        fluid(quality:100){
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                            }
                            PrevImg
                            NextImg
                        }
                    }
                }
            
        `}
        render={(data) => (
            <Portfolio corporate2Json={data.corporate2Json}/>
        )}
    />
  )