import React from 'react';
import {ServicesWrapper,ServicesLeft,ServicesRight,ServicesCard,
ServicesCardImg,ServicesCardIcon,ServicesCardHeading,ServicesCardPara,Heading,HeadingWrapper,
ServicesRow
} from './services.style';
import {Container, Row, Col} from '@bootstrap-styled/v4';
import GatsImg from "gatsby-image";
import Fade from "react-reveal/Fade";
import { useStaticQuery, graphql } from 'gatsby';

const Services = () => {
    const JSONData  = useStaticQuery(graphql`
        query{
            corporate2Json {
                Services {
                    DelayConstant
                    Heading
                    ServicesRight1{
                        ServicesCardIcon
                        ServicesCardHeading
                        ServicesCardPara
                    }
                    ServicesRight2{
                        ServicesCardIcon
                        ServicesCardHeading
                        ServicesCardPara
                    }
                    ServicesRight3{
                        ServicesLeftImg{
                            childImageSharp{
                                fluid(quality: 100){
                                ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    
                }
            }
        }
    `);

    return (
        <ServicesWrapper id="servicesContainer">
            <Container>
                <Row>
                    <Col md="12">
                        <HeadingWrapper>
                            <Fade delay={1*JSONData.corporate2Json.Services.DelayConstant}>
                                <Heading>{JSONData.corporate2Json.Services.Heading}</Heading>
                            </Fade>
                        </HeadingWrapper>
                    </Col>
                </Row>
                <ServicesRow>
                    <Col md="12" lg="6" xl="7">
                        <ServicesRight>
                            <Row>
                                <Col lg="6" md="6" sm="12">
                                {
                                    JSONData.corporate2Json.Services.ServicesRight1.map((item,idx) => {
                                    return <Fade delay={(idx+1)*JSONData.corporate2Json.Services.DelayConstant}><ServicesCard>
                                            <ServicesCardImg>
                                                <ServicesCardIcon src={item.ServicesCardIcon} alt=""/>
                                            </ServicesCardImg>
                                            <ServicesCardHeading>{item.ServicesCardHeading}</ServicesCardHeading>
                                            <ServicesCardPara>{item.ServicesCardPara}</ServicesCardPara>
                                        </ServicesCard>
                                    </Fade>
                                    })
                                }
                                </Col>
                                <Col lg="6" md="6" sm="12">
                                {
                                    JSONData.corporate2Json.Services.ServicesRight2.map((item,idx) => {
                                    return  <Fade delay={(idx+1)*JSONData.corporate2Json.Services.DelayConstant}> <ServicesCard>
                                            <ServicesCardImg>
                                                <ServicesCardIcon src={item.ServicesCardIcon} alt=""/>
                                            </ServicesCardImg>
                                            <ServicesCardHeading>{item.ServicesCardHeading}</ServicesCardHeading>
                                            <ServicesCardPara>{item.ServicesCardPara}</ServicesCardPara>
                                        </ServicesCard>
                                    </Fade>
                                    })
                                }
                                </Col>
                            </Row>
                        </ServicesRight>
                    </Col>
                    <Col md="12" lg="6" xl="5">
                        <ServicesLeft>
                            <Fade delay={1*JSONData.corporate2Json.Services.DelayConstant}>
                                <GatsImg 
                                    fluid={JSONData.corporate2Json.Services.ServicesRight3.ServicesLeftImg.childImageSharp.fluid} 
                                    alt=""
                                    imgStyle={{
                                        marginBottom:"0px"
                                    }}
                                />
                            </Fade>
                        </ServicesLeft>
                    </Col>
                </ServicesRow>
            </Container>
        </ServicesWrapper>

    );
}

export default Services;