import React from 'react';
import {BannerSection,
    BannerContents,
    BannerContentLeft,
    BannerBtn,
    BannerH3,
    BannerH4
} from './banner.style'
import {Container} from '@bootstrap-styled/v4';
import Fade from "react-reveal/Fade";
import { useStaticQuery, graphql } from 'gatsby';

const Banner = () => {
    const JSONData  = useStaticQuery(graphql`
        query{
            corporate2Json {
                Banner {
                    BannerBtn
                    BannerH3
                    BannerH4
                    DelayConstant
                }
            }
        }
    `);
    return (
        <BannerSection id="homeContainer">
            <Container>
                <BannerContents>
                    <BannerContentLeft>
                        <Fade delay={1*JSONData.corporate2Json.Banner.DelayConstant}>
                            <BannerH3>{JSONData.corporate2Json.Banner.BannerH3}</BannerH3>
                            <BannerH4>{JSONData.corporate2Json.Banner.BannerH4}</BannerH4>
                            <BannerBtn href="#">{JSONData.corporate2Json.Banner.BannerBtn}</BannerBtn>
                        </Fade>
                    </BannerContentLeft>
                </BannerContents>
            </Container>
        </BannerSection>
    );
}

export default Banner;