import React from 'react';
import {AboutSection,AboutRight,AboutLeft,AboutCardImg,
    AboutRightH3,AboutRightPara,AboutRightBtn,AboutRow
} from './about.style';
import {Container, Col} from '@bootstrap-styled/v4';
import GatsImg from "gatsby-image";
import Fade from "react-reveal/Fade";
import { useStaticQuery, graphql } from 'gatsby';

const ImageText = () => {
    const JSONData  = useStaticQuery(graphql`
        query{
            corporate2Json {
                ImageText {
                    DelayConstant
                    AboutCardIcon{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    AboutRight{
                        AboutRightH3
                        AboutRightPara
                        AboutRightBtn
                    }
                }
            }
        }
    `);
    return (
        <AboutSection id="imageTextContainer">
            <Container>
                <AboutRow>
                    <Col md="12" lg="7">
                        <AboutLeft>
                            <Fade delay={1*JSONData.corporate2Json.ImageText.DelayConstant}>
                                <AboutCardImg>
                                    <GatsImg 
                                        fluid={JSONData.corporate2Json.ImageText.AboutCardIcon.childImageSharp.fluid} 
                                        alt=""
                                    />
                                </AboutCardImg>
                            </Fade>
                        </AboutLeft>
                    </Col>
                    <Col md="12" lg="5">
                        <AboutRight>
                            <Fade delay={1*JSONData.corporate2Json.ImageText.DelayConstant}>
                                <AboutRightH3>{JSONData.corporate2Json.ImageText.AboutRight.AboutRightH3}</AboutRightH3>
                                <AboutRightPara>{JSONData.corporate2Json.ImageText.AboutRight.AboutRightPara}</AboutRightPara>
                                <AboutRightBtn href="#">{JSONData.corporate2Json.ImageText.AboutRight.AboutRightBtn}</AboutRightBtn>
                            </Fade>
                        </AboutRight>
                    </Col>
                </AboutRow>
            </Container>
        </AboutSection>

    );
}

export default ImageText;