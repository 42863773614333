import {createGlobalStyle} from 'styled-components';
const GlobalStyle = createGlobalStyle`

    body{
        font-family: 'Source Sans Pro', sans-serif;
    }
    h1,h2,h3,h4,h5,h6{
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 600;
    }
    a{
        font-size:16px;
    }
    form{
        margin-bottom:0px;
    }
`;

export default GlobalStyle;